/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdRefresh } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { Divider, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import Splash from '~/components/Splash/Inside';

import {
    TotalContainer,
    ChartsContainer,
    TableArea,
    RowArea,
} from '~/pages/Platform/Reports/style';
import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';
import lng_all from '~/util/LangMessages';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

export default function QnAUsage({ dialogSettings, handleError }) {
    const { id } = dialogSettings;

    const colors = GetPluginspaceTheme(useTheme());
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_labels = lng_all && lng_all[lang] ? lng_all[lang] : {};

    // const date_format = settings.locale;
    // const date_opt = {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    // };
    // const time_opt = {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     hourCycle: 'h23',
    // };

    // const minDate = new Date(subMonths(new Date(), 1));
    // const [fromDate, setFromDate] = useState(new Date(subDays(new Date(), 7)));
    // const [untilDate, setUntilDate] = useState(new Date());

    const [usageData, setUsageData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const headers_ans_sources = [
        {
            id: 'name',
            label: (
                <LocaleMessage msg="page.dialogs.info.qna_usage.answer_sources" />
            ),
        },
        {
            id: 'value',
            label: <LocaleMessage msg="table.headers.quantity" />,
            align: 'right',
        },
    ];

    function parseData(data = {}) {
        const answer_sources = [
            'dialog_content',
            'vertex_search',
            'google_search',
            'fallback',
        ];

        const answers = data.answers || {};

        const sources_data = answer_sources.map(s => {
            const label_key = `page.qna.statistics.usage.answer_sources.${s}`;
            return {
                id: s,
                source: s,
                value: answers[s] || 0,
                name: lng_labels[label_key],
            };
        });

        const parsed = {
            ...data,
            source_list: sources_data,
        };
        setUsageData(parsed);
    }

    async function loadData() {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/qna/usage`)
            .then(response => {
                const { data } = response;
                parseData(data);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [date_loc]);

    // const handleFromDateChange = date => {
    //     setFromDate(date);
    // };

    // const handleUntilDateChange = date => {
    //     setUntilDate(date);
    // };

    return (
        <div className="col-12 mt-3">
            <div
                className="sidecard-header"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                }}
            >
                <div className="col-12 row">
                    <div className="col-md-6 col-12 px-0">
                        <h2>
                            <LocaleMessage msg="page.dialogs.info.qna_usage" />
                        </h2>
                        <p />
                        {/* <LocaleMessage msg="page.dialogs.info.logs.label.max_period.1_month" /> */}
                    </div>
                    <div className="col-md-6 col-12 px-0 text-right">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdRefresh color="#fff" />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => {
                                loadData();
                            }}
                        >
                            <LocaleMessage msg="button.refresh_data" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <RowArea className="mb-5">
                            <p className="card-title">
                                <LocaleMessage msg="page.dialogs.info.qna_usage.current_month" />
                            </p>
                        </RowArea>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                classes="col-md-4 col-12"
                                title={
                                    <LocaleMessage msg="page.dialogs.info.qna_usage.total.messages" />
                                }
                                value={
                                    usageData && usageData.total
                                        ? usageData.total
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-4 col-12"
                                title={
                                    <LocaleMessage msg="page.dialogs.info.qna_usage.total.generated" />
                                }
                                value={
                                    usageData && usageData.generated
                                        ? usageData.generated
                                        : 0
                                }
                            />
                        </TotalContainer>

                        <Divider />

                        <RowArea className="mt-5 mb-5">
                            <p className="card-title">
                                <LocaleMessage msg="page.dialogs.info.qna_usage.generation" />
                            </p>
                        </RowArea>

                        <TotalContainer className="mt-5 mb-5">
                            <CounterCard
                                classes="col-md-3 col-12"
                                title={
                                    <LocaleMessage msg="page.dialogs.info.qna_usage.tokens.input" />
                                }
                                bordered={false}
                                value={
                                    usageData &&
                                    usageData.tokens &&
                                    usageData.tokens.input
                                        ? usageData.tokens.input
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title={
                                    <LocaleMessage msg="page.dialogs.info.qna_usage.tokens.output" />
                                }
                                bordered={false}
                                value={
                                    usageData &&
                                    usageData.tokens &&
                                    usageData.tokens.output
                                        ? usageData.tokens.output
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title={
                                    <LocaleMessage msg="page.dialogs.info.qna_usage.tokens.online_searches" />
                                }
                                bordered={false}
                                value={
                                    usageData &&
                                    usageData.searches &&
                                    usageData.searches.google_search
                                        ? usageData.searches.google_search
                                        : 0
                                }
                            />
                        </TotalContainer>

                        <Divider />

                        <RowArea className="mt-5 mb-5">
                            <p className="card-title">
                                <LocaleMessage msg="page.dialogs.info.qna_usage.answer_sources" />
                            </p>
                        </RowArea>

                        <ChartsContainer className="row mb-5">
                            <ReportPieChart
                                classes="col-md-5 col-12"
                                color={colors.dashboard_graphs}
                                data={usageData.source_list}
                                dataKey="value"
                                customization={{
                                    height: 300,
                                    innerRadius: 80,
                                    outerRadius: 120,
                                }}
                            />
                            <TableArea
                                className="col-md-4 col-12 table-responsive-material"
                                style={{
                                    minHeight: '350px',
                                    justifyContent: 'center',
                                }}
                            >
                                <DataTable
                                    maxHeight="100%"
                                    headerColumns={headers_ans_sources}
                                    data={usageData.source_list || []}
                                    orderDirection="desc"
                                    orderColumn="value"
                                    hasActions={false}
                                    sortable
                                    defaultRowsPerPage={10}
                                    hasFooter={false}
                                />
                            </TableArea>
                        </ChartsContainer>
                        {/* <TotalContainer className="mt-5 mb-5">
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="CONTEÚDO DO DIÁLOGO"
                                value={
                                    usageData &&
                                    usageData.answers &&
                                    usageData.answers.dialog_content
                                        ? usageData.answers.dialog_content
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="BUSCA EM DOCUMENTOS"
                                value={
                                    usageData &&
                                    usageData.answers &&
                                    usageData.answers.vertex_search
                                        ? usageData.answers.vertex_search
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="BUSCA NA INTERNET"
                                value={
                                    usageData &&
                                    usageData.answers &&
                                    usageData.answers.google_search
                                        ? usageData.answers.google_search
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="FALLBACK"
                                value={
                                    usageData &&
                                    usageData.answers &&
                                    usageData.answers.fallback
                                        ? usageData.answers.fallback
                                        : 0
                                }
                            />
                        </TotalContainer>
                        <Divider />
                        <TotalContainer className="mt-5 mb-5">
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="CONTEÚDO DO DIÁLOGO"
                                value={
                                    usageData &&
                                    usageData.searches &&
                                    usageData.searches.dialog_content
                                        ? usageData.searches.dialog_content
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="BUSCA EM DOCUMENTOS"
                                value={
                                    usageData &&
                                    usageData.searches &&
                                    usageData.searches.vertex_search
                                        ? usageData.searches.vertex_search
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="BUSCA NA INTERNET"
                                value={
                                    usageData &&
                                    usageData.searches &&
                                    usageData.searches.google_search
                                        ? usageData.searches.google_search
                                        : 0
                                }
                            />
                            <CounterCard
                                classes="col-md-3 col-12"
                                title="FALLBACK"
                                value={
                                    usageData &&
                                    usageData.searches &&
                                    usageData.searches.fallback
                                        ? usageData.searches.fallback
                                        : 0
                                }
                            />
                        </TotalContainer> */}
                    </>
                )}
            </div>
        </div>
    );
}

QnAUsage.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
