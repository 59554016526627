import { shade } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
    border-top: 0.5vh solid ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100%;
    background-color: ${props => {
        const color = props.theme.palette.primary.main;
        return shade(0.95, color);
    }};
    color: #fff;
    padding: 1.5vh 1vw;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1 1;
        padding: 0px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title {
            color: #fff;
            font-size: 1.35vh !important;
        }

        .big-value {
            font-size: 2.75vh;
            span {
                margin-top: 0.5vh;
            }
        }
    }
`;

export const MissionDialog = styled.div`
    width: 100%;
    overflow: hidden;

    .mission-load {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mission-box-main {
        width: 100%;
    }

    .mission-main {
        display: flex;
        margin-bottom: -15px;
        flex-direction: row;
        height: 40vh;
        justify-content: center;
    }

    .mission-main-content {
    }

    .mission-side {
        border-right: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;

        .mission-side-title {
            font-weight: bold;
            color: #fff !important;
            margin-top: 15px;
            width: 100%;
            text-align: center;
        }

        .mission-side-content {
            padding: 30px 15px 30px 15px;
            display: flex;
            flex-direction: column;
            align-items: end;
        }
    }

    .mission-main-header {
        width: 100%;
        border-bottom: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        padding: 16px 16px 16px 32px;

        .mission-main-header-info {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    .mission-main-scroll {
        background-color: #000;
    }

    .mission-main-content {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .mission-item .bubble {
        background-color: ${props => props.theme.palette.primary.main};
        padding: 12px;

        .text-muted {
            color: #ccc !important;
            font-size: smaller;
        }
    }

    .mission-main-footer {
        border-top: 1px solid ${props => props.theme.palette.primary.main};
        background-color: #000;
        z-index: 1000;
    }

    textarea {
        border-radius: 1.5vh;
        padding: 1vh 2vh 1vh 2vh;
    }

    .MuiIconButton-label {
        color: ${props => props.theme.palette.primary.main};
    }

    .MuiSelect-select.MuiSelect-select {
        padding-right: 0px;
    }

    .MuiSelect-icon {
        display: none;
    }
`;

export const FullwidthDialogActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const MessageShortcut = styled.div`
    background-color: ${props => props.theme.palette.primary.main};
    border-radius: 5px;
    color: #fff;
    padding: 12px;
    font-weight: ${props => {
        return props.bold ? 'bold' : 'regular';
    }};

    :hover {
        cursor: pointer;
        font-weight: bold;
        border: 1px solid #fff;
    }
`;
