import styled from 'styled-components';

export const Container = styled.div`
    h6 {
        text-align: center;
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }} !important;
    }
`;
