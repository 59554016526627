/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdAdd, MdCancel, MdLaunch, MdCheck, MdClose } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Chip,
    IconButton,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    DialogContentText,
    Tooltip,
} from '@material-ui/core';

import DataTable from '~/components/DataTable';
import ExitDialog from '~/components/ExitDialog';
// import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import { IconArea } from '~/styles/custom';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

// import lng_labels from '~/util/LangMessages';
const update_time = 30 * 1000;

export default function QnADocuments({ dialogSettings, handleError }) {
    // const loc = settings.locale;
    // const lang = loc && loc.code ? loc.code : 'pt_BR';
    // const lng_all = lng_labels[lang];
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { id } = dialogSettings;

    const [isLoading, setIsLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(true);
    const [isTraining, setIsTraining] = useState(true);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [allowTraining, setAllowTraining] = useState(true);
    // const [trainingBody, setTrainingBody] = useState({});

    const [albumList, setAlbumList] = useState([]);
    const [currGallery, setCurrGallery] = useState(null);
    const [albumFileList, setAlbumFileList] = useState([]);
    const [selectedUpload, setSelectedUpload] = useState([]);

    const [contentList, setContentList] = useState([]);
    const [contentKeys, setContentKeys] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);

    const [showTrainingDialog, setShowTrainingDialog] = useState(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);

    const [exitConfirmOpen, setExitConfirmOpen] = useState(false);

    async function getTrainingOperation() {
        await api
            .get(`/dialogs/${id}/qna/training`)
            .then(response => {
                const { data } = response;
                const doc_training = data.documents;
                setIsTraining(doc_training.is_training);
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function loadAgentDocuments() {
        setSelectedRows([]);
        setSelectedUpload([]);
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/qna/documents`)
            .then(response => {
                const doc_keys = {};
                let needs_training = false;
                const documents = (response.data || []).map(d => {
                    const d_file = d.file || {};
                    const updated = new Date(d.updated);
                    doc_keys[d_file.id] = true;
                    if (!d.synced) needs_training = true;
                    const name_label = (
                        <>
                            {!d_file.active ? (
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label={
                                        <LocaleMessage msg="page.qna.documents.list.removed" />
                                    }
                                    style={{ marginRight: '10px' }}
                                />
                            ) : null}

                            <span
                                style={{
                                    ...(!d_file.active && {
                                        textDecoration: 'line-through',
                                    }),
                                }}
                            >
                                {d.file ? d.file.name : ''}
                            </span>
                        </>
                    );
                    return {
                        ...d,
                        file_name: name_label,
                        file_active: d.file ? d.file.active : false,
                        synced_icon: d.synced ? (
                            <MdCheck size={20} />
                        ) : (
                            <MdClose size={20} />
                        ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });

                setAllowTraining(needs_training);
                setContentKeys(doc_keys);
                setContentList(documents);
            })
            .catch(error => handleError(error));

        setIsLoading(false);
        getTrainingOperation();
    }

    async function loadGalleries() {
        await api
            .get(`albums?type=documents`)
            .then(response => {
                const { data } = response;
                setAlbumList(data);
                setCurrGallery(data && data[0] ? data[0].id : null);
            })
            .catch(error => handleError(error));
    }

    async function loadAlbumFiles(_id) {
        setSelectedUpload([]);
        if (_id) {
            setInternalLoading(true);
            await api
                .get(`albums/${_id}/files`)
                .then(response => {
                    const list = (response.data || []).filter(f => {
                        return f.format.toUpperCase() === 'PDF';
                    });
                    const files = list.map(f => {
                        const updated = new Date(f.updated);
                        const name_label = (
                            <>
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label={f.format.toUpperCase()}
                                    style={{ marginRight: '10px' }}
                                />

                                <span>{f.name}</span>
                            </>
                        );
                        return {
                            ...f,
                            name_label,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setAlbumFileList(files);
                })
                .catch(error => handleError(error));
            setInternalLoading(false);
        } else {
            setAlbumFileList([]);
        }
    }

    useEffect(() => {
        loadGalleries();
        loadAgentDocuments();
    }, []);

    useEffect(() => {
        loadAlbumFiles(currGallery);
    }, [currGallery]);

    useEffect(() => {
        const interval = setInterval(() => {
            getTrainingOperation();
        }, update_time);
        return () => clearInterval(interval);
    }, [id]);

    function closeDialogs() {
        setShowTrainingDialog(false);
        setShowRemoveDialog(false);
        setMediaDialogOpen(false);
    }

    async function handleRemove() {
        closeDialogs();
        setIsLoading(true);

        const delete_list = JSON.stringify(selectedRows);
        await api
            .delete(`dialogs/${id}/qna/documents?list=${delete_list}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
            })
            .catch(error => handleError(error));
        loadAgentDocuments();
    }

    async function handleSubmit() {
        closeDialogs();
        setIsLoading(true);

        const data = {
            files: selectedUpload,
        };
        await api
            .post(`dialogs/${id}/qna/documents`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
        loadAgentDocuments();
    }

    async function handleSubmitTraining() {
        closeDialogs();
        setIsLoading(true);
        setIsTraining(true);

        // const data = trainingBody;
        const data = {};

        await api
            .post(`dialogs/${id}/qna/documents/training`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
        loadAgentDocuments();
    }

    function handleMediaDialogClose() {
        setSelectedUpload([]);
        setMediaDialogOpen(false);
    }

    function renderRemoveDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showRemoveDialog}
                onClose={() => {
                    setShowRemoveDialog(false);
                }}
                title={
                    <>
                        <LocaleMessage msg="page.qna.documents.list.remove" />
                    </>
                }
                content={
                    <>
                        <DialogContentText>
                            <LocaleMessage msg="page.qna.documents.list.remove.confirmation" />
                            <br />
                            <LocaleMessage msg="page.qna.documents.list.take_time" />
                        </DialogContentText>
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setShowRemoveDialog(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => handleRemove(),
                        disabled: selectedRows.length === 0,
                        label: (
                            <span>
                                <LocaleMessage msg="button.remove" />
                                {selectedRows.length
                                    ? ` (${selectedRows.length})`
                                    : ''}
                            </span>
                        ),
                    },
                ]}
            />
        );
    }

    function renderTrainingDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showTrainingDialog}
                onClose={() => {
                    setShowTrainingDialog(false);
                }}
                title={
                    <>
                        <LocaleMessage msg="page.qna.documents.list.send" />
                    </>
                }
                content={
                    <>
                        <DialogContentText>
                            {/* <div className="col-12">
                                    <FormSwitch
                                        value={trainingBody.clean_all || false}
                                        onChange={event =>
                                            setTrainingBody({
                                                ...trainingBody,
                                                clean_all: event.target.checked,
                                            })
                                        }
                                        label={
                                            <LocaleMessage msg="page.qna.documents.list.mode.clear" />
                                        }
                                    />
                                </div> */}
                            <span>
                                <LocaleMessage msg="page.qna.documents.list.confirmation" />
                                <br />
                                <LocaleMessage msg="page.qna.documents.list.take_time" />
                                {/* <LocaleMessage
                                            msg={`page.qna.suggestions.training.${
                                                trainingBody.clean_all
                                                    ? 'ignore'
                                                    : 'keep'
                                            }.description`}
                                        /> */}
                            </span>
                        </DialogContentText>
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setShowTrainingDialog(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => handleSubmitTraining(),
                        label: <LocaleMessage msg="button.send" />,
                    },
                ]}
            />
        );
    }

    function renderGallery() {
        const headCells = [
            {
                id: 'name_label',
                label: <LocaleMessage msg="page.qna.documents.list.document" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const currGalleryObj = albumList.find(g => {
            return g.id === currGallery;
        });

        const filtered_files = albumFileList.filter(f => {
            return !contentKeys[f.id];
        });

        return (
            <div className="col-12">
                <div className="row">
                    <div className="col-11">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.telepresence.form.gallery" />
                            </InputLabel>
                            <Select
                                value={currGallery || ''}
                                onChange={event =>
                                    setCurrGallery(event.target.value)
                                }
                            >
                                {albumList.map(g => (
                                    <MenuItem key={g.id} value={g.id}>
                                        {g.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <IconArea className="col-1 px-0">
                        <Tooltip
                            title={
                                <LocaleMessage msg="page.qna.documents.list.gallery.go" />
                            }
                            placement="top"
                        >
                            <IconButton
                                onClick={() => {
                                    setExitConfirmOpen(true);
                                }}
                            >
                                <MdLaunch size={20} />
                            </IconButton>
                        </Tooltip>
                    </IconArea>
                    <div className="col-12">
                        <div className="sidecard-body">
                            {internalLoading ? (
                                <div
                                    className="mt-5"
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ReactLoading
                                        type="bars"
                                        color="#c8c8c8"
                                        height={50}
                                        width={50}
                                    />
                                </div>
                            ) : (
                                <>
                                    {currGalleryObj ? (
                                        <>
                                            <div
                                                className="mt-5"
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <LocaleMessage msg="page.qna.documents.list.warning.format" />
                                            </div>
                                            {filtered_files.length > 0 ? (
                                                <DataTable
                                                    headerColumns={headCells}
                                                    data={filtered_files}
                                                    orderDirection="asc"
                                                    orderColumn="identifier"
                                                    handleTableRowClick={() => {}}
                                                    sortable
                                                    hasHeader
                                                    selectable
                                                    selectedRows={
                                                        selectedUpload
                                                    }
                                                    setSelectedRows={s => {
                                                        setSelectedUpload(s);
                                                    }}
                                                />
                                            ) : (
                                                <div
                                                    className="mt-5"
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <span className="mt-3 mb-3">
                                                        <LocaleMessage msg="page.qna.documents.list.bind_contents.empty" />
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderMediaDialog() {
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={<LocaleMessage msg="page.qna.documents.list.select" />}
                content={renderGallery()}
                size="md"
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'add',
                        onClick: () => handleSubmit(),
                        disabled: selectedUpload.length === 0,
                        label: (
                            <span>
                                <LocaleMessage msg="button.add" />
                                {selectedUpload.length
                                    ? ` (${selectedUpload.length})`
                                    : ''}
                            </span>
                        ),
                    },
                ]}
            />
        );
    }

    function renderExitDialog() {
        return (
            <ExitDialog
                open={exitConfirmOpen}
                onClose={() => setExitConfirmOpen(false)}
                destination={`/galleries/${
                    currGallery ? `${currGallery}/edit` : ''
                }`}
            />
        );
    }

    function renderContentList() {
        const headCells = [
            {
                id: 'file_name',
                label: <LocaleMessage msg="page.qna.documents.list.document" />,
            },
            ...(isTraining
                ? []
                : [
                      {
                          id: 'synced_icon',
                          label: (
                              <LocaleMessage msg="page.qna.documents.list.synced" />
                          ),
                          width: '10%',
                      },
                      {
                          id: 'updated',
                          label: <LocaleMessage msg="table.headers.updated" />,
                          order_by: 'updated_timestamp',
                          width: '20%',
                      },
                  ]),
        ];

        const rowActions = isTraining
            ? []
            : [
                  {
                      id: 'remove',
                      label: <LocaleMessage msg="button.remove" />,
                      icon: <MdCancel />,
                      action: (e, _id) => {
                          setSelectedRows([_id]);
                      },
                  },
              ];

        const headerActions = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdCancel />,
                action: () => {
                    setShowRemoveDialog(true);
                },
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <Button
                    className="mt-3 mb-5 p-3"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowTrainingDialog(true)}
                    fullWidth
                    size="large"
                    disabled={isTraining || !allowTraining}
                >
                    {isTraining ? (
                        <>
                            <ReactLoading
                                type="spin"
                                color="primary"
                                height={30}
                                width={30}
                            />
                            <span className="ml-3">
                                <LocaleMessage msg="page.qna.documents.list.training" />
                            </span>
                        </>
                    ) : (
                        <LocaleMessage msg="page.qna.documents.list.sync" />
                    )}
                </Button>
                <DataTable
                    headerColumns={headCells}
                    data={contentList}
                    orderDirection="asc"
                    orderColumn="name"
                    handleTableRowClick={() => {}}
                    hasActions
                    rowActions={rowActions}
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        selectedRows.length === 0 ? (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<MdAdd />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                                onClick={() => {
                                    setSelectedUpload([]);
                                    setSelectedRows([]);
                                    setMediaDialogOpen(true);
                                }}
                                disabled={isTraining}
                            >
                                <LocaleMessage msg="page.qna.documents.list.add" />
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<MdCancel />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                                onClick={() => {
                                    setShowRemoveDialog(true);
                                }}
                                disabled={isTraining}
                            >
                                <LocaleMessage msg="page.qna.documents.list.remove" />
                            </Button>
                        )
                    }
                    selectable={!isTraining}
                    selectedActions={headerActions}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.dialogs.info.documents" />
                </h2>
            </div>
            <div
                className="mb-3"
                style={{
                    width: '100%',
                    padding: '0px 25px',
                }}
            >
                <LocaleMessage msg="page.qna.beta.title" />
                <br />
                <LocaleMessage msg="page.qna.documents.list.warning_1" />
                <br />
                <LocaleMessage msg="page.qna.documents.list.warning_2" />
                <br />
                <LocaleMessage msg="page.qna.documents.list.warning.format" />
            </div>

            <div className="sidecard-body">
                {renderExitDialog()}
                {mediaDialogOpen ? renderMediaDialog() : null}
                {showRemoveDialog ? renderRemoveDialog() : null}
                {showTrainingDialog ? renderTrainingDialog() : null}
                {isLoading ? <Splash /> : <>{renderContentList()}</>}
            </div>
        </>
    );
}

QnADocuments.defaultProps = {};

QnADocuments.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
