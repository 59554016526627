/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdForum } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import Page404 from '~/pages/Open/Page404';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import Intents from './Intents';
import Logs from './Logs';
import PluginbotQnA from './PluginbotQnA/v2';
import PluginbotQnASuggestions from './PluginbotQnA/v2/QnASuggestions';
import PluginbotQnAUsage from './PluginbotQnA/v2/QnAUsage';
import Settings from './Settings';
import Subjects from './Subjects';

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
        },
    };
});

const internal_pages = {
    // settings: {
    //     value: 'settings',
    //     label_code: 'page.dialogs.info.settings',
    // },
    qna: {
        value: 'qna',
        label_code: 'page.dialogs.info.content',
    },
    intents: {
        value: 'intents',
        label_code: 'page.dialogs.info.intents',
    },
    subjects: {
        value: 'subjects',
        label_code: 'page.dialogs.info.subjects',
    },
    logs: {
        value: 'logs',
        label_code: 'page.dialogs.info.logs',
    },
    qna_suggestions: {
        value: 'qna_suggestions',
        label_code: 'page.dialogs.info.qna_suggestions',
    },
    qna_usage: {
        value: 'qna_usage',
        label_code: 'page.dialogs.info.qna_usage',
    },
};

const provider_pages = {
    'IBM Watson Skill': ['intents', 'subjects'],
    PluginbotQnA: ['qna', 'qna_suggestions', 'qna_usage'],
};

export default function DialogInfo(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;

    const [queryTab, setQueryTab] = useQueryState('tab', '');
    const [isLoading, setIsLoading] = useState(true);
    const [dialog, setDialog] = useState({});
    const [value, setValue] = useState(queryTab);
    const [pages, setPages] = useState([]);

    const classes = useStyles();

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
        setQueryTab(newValue);
    };

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadConversationContent() {
        await api
            .get(`dialogs/${id}`)
            .then(response => {
                const { data } = response;
                setDialog(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function selectPages() {
        const d_pages =
            dialog && dialog.provider && provider_pages[dialog.provider]
                ? provider_pages[dialog.provider]
                : [];
        const available_pages = d_pages.map(p => {
            return internal_pages[p];
        });
        available_pages.push(internal_pages.logs);
        setPages(available_pages);
        if (
            dialog &&
            dialog.provider &&
            !d_pages.includes(value) &&
            value !== 'logs'
        ) {
            handleChangeTab(null, available_pages[0].value);
        }
    }

    useEffect(() => {
        loadConversationContent();
    }, []);

    useEffect(() => {
        selectPages();
    }, [dialog]);

    const page_components = {
        qna: PluginbotQnA,
        qna_suggestions: PluginbotQnASuggestions,
        qna_usage: PluginbotQnAUsage,
        intents: Intents,
        subjects: Subjects,
        settings: Settings,
        logs: Logs,
    };

    function renderInternalPage(page) {
        const Page = page_components[page] || Page404;

        return (
            <Page
                dialogSettings={dialog}
                reloadSettings={() => loadConversationContent()}
                handleError={error => requestError(error)}
            />
        );
    }

    return (
        <PageContent
            title={
                dialog && dialog.name ? (
                    dialog.name.toUpperCase()
                ) : (
                    <LocaleMessage msg="page.dialogs.info.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/dialogs',
                    title: <LocaleMessage msg="breadcrumbs.dialogs" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                <div className="col-12 mb-5 mt-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.dialogs.info.settings" />
                        }
                        hide
                        Icon={MdForum}
                    >
                        <>
                            {pages.length > 0 ? (
                                <>
                                    <Tabs
                                        indicatorColor="primary"
                                        value={value}
                                        onChange={handleChangeTab}
                                        className={classes.tabs}
                                        centered
                                    >
                                        {pages.map(p => {
                                            return (
                                                <Tab
                                                    key={p.value}
                                                    label={
                                                        <LocaleMessage
                                                            msg={p.label_code}
                                                        />
                                                    }
                                                    value={p.value}
                                                />
                                            );
                                        })}
                                    </Tabs>

                                    <div>{renderInternalPage(value)}</div>
                                </>
                            ) : null}
                        </>
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

DialogInfo.propTypes = {
    match: PropTypes.object.isRequired,
};
