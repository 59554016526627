/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdOutlineThumbDown,
    MdOutlineThumbUp,
    MdRefresh,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import {
    Button,
    Tab,
    Tabs,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DataTable from '~/components/DataTable';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import QnAExamples from '~/components/QnAExamples';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { ParameterArea } from '../styles';

const internal_pages = [
    {
        value: 'training',
        label_code: 'page.dialogs.info.qna_suggestions.training',
    },
    {
        value: 'answer',
        label_code: 'page.dialogs.info.qna_suggestions.answer',
    },
];

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
        },
    };
});

export default function QnASuggestions(props) {
    const { dialogSettings, handleError } = props;
    const { id, language } = dialogSettings;

    const classes = useStyles();
    const settings = useSelector(state => state.settings || null);
    const date_format = settings.locale;
    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };

    const [contentList, setContentList] = useState([]);
    const [dialogContents, setDialogContents] = useState({});
    const [dialogNodes, setDialogNodes] = useState({});

    const [suggestionType, setSuggestionType] = useState(
        internal_pages[0].value
    );
    const [suggestionList, setSuggestionList] = useState([]);

    const [editSuggestion, setEditSuggestion] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(true);

    const [body, setBody] = useState({});
    const [currNode, setCurrNode] = useState({});

    const headers_training = [
        {
            id: 'created',
            label: <LocaleMessage msg="table.headers.date" />,
            order_by: 'created_timestamp',
        },
        {
            id: 'suggestion_content',
            label: (
                <LocaleMessage msg="page.qna.suggestions.training.operation.content" />
            ),
        },
        {
            id: 'suggestion_text',
            label: '',
        },
        {
            id: 'counter',
            label: <LocaleMessage msg="table.headers.quantity" />,
        },
    ];

    const headers_answer = [
        {
            id: 'created',
            label: <LocaleMessage msg="table.headers.date" />,
            order_by: 'created_timestamp',
        },
        {
            id: 'suggestion_content',
            label: (
                <LocaleMessage msg="page.qna.suggestions.training.operation.content" />
            ),
        },
        {
            id: 'suggestion_text',
            label: '',
            width: '60%',
        },
    ];

    const headers = {
        training: headers_training,
        answer: headers_answer,
    };

    function getSuggestionContent(s) {
        return s.content && s.content.id
            ? s.content.name
            : s.identifier || 'NEW TRAINING';
    }

    async function loadSuggestions() {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/qna/suggestions?type=${suggestionType}`)
            .then(response => {
                const { data } = response;
                const filtered_data = data.filter(s => {
                    return (
                        !!s.text && s.content && s.content.type === 'subject'
                    );
                });
                const list = filtered_data.map(s => {
                    const datetime = new Date(s.updated);
                    const date = datetime.toLocaleDateString(
                        date_format.format,
                        date_opt
                    );
                    const time = datetime.toLocaleTimeString([], time_opt);

                    return {
                        ...s,
                        suggestion_content: getSuggestionContent(s),
                        suggestion_text: s.text,
                        created_date: date,
                        created_time: time,
                        created: `${date} - ${time}`,
                        created_timestamp: datetime.toISOString(),
                    };
                });
                setSuggestionList(list);
                setIsLoading(false);
            })
            .catch(error => handleError(error));
    }

    async function loadContents() {
        if (!id) {
            setContentList([]);
            setDialogContents({});
            return;
        }
        await api
            .get(`dialogs/${id}/qna/contents?language=${language}`)
            .then(response => {
                const list = response.data;
                const contents = {};
                const mapped = {};
                list.forEach(c => {
                    const c_type = c.type;
                    if (!contents[c_type]) contents[c_type] = [];
                    const item = {
                        id: c.id,
                        identifier: c.identifier,
                        type: c_type,
                        display_name: c.display_name,
                        grouping: c.grouping,
                        examples: c.examples || [],
                    };
                    contents[c_type].push(item);
                    mapped[c.id] = item;
                });
                setContentList(list);
                setDialogContents(contents);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    async function loadNodes() {
        setIsLoading(true);
        if (!id) {
            setDialogNodes({});
            return;
        }
        await api
            .get(`dialogs/${id}/qna/nodes`)
            .then(response => {
                const nodes = response.data;
                setDialogNodes(nodes);
            })
            .catch(error => {
                handleError(error);
            });
        setIsLoading(false);
    }

    async function loadAll() {
        setIsLoading(true);
        await Promise.all([loadSuggestions(), loadContents(), loadNodes()]);
        setIsLoading(false);
    }

    async function loadNode(_id) {
        let dialog_node = null;
        if (_id && _id !== 'new') {
            setInternalLoading(true);
            dialog_node = await api
                .get(`dialogs/${id}/qna/nodes/${_id}`)
                .then(response => {
                    const ans = response.data;
                    return ans;
                })
                .catch(error => {
                    handleError(error);
                    return null;
                });
        }

        setInternalLoading(false);
        setIsLoading(false);
        return dialog_node;
    }

    async function handleSuggestionSubmit() {
        setIsLoading(true);
        await api
            .put(`dialogs/${id}/qna/suggestions/${editSuggestion.id}`, body)
            .then(() => {
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
        setEditSuggestion(null);
        await loadAll();
    }

    async function reloadData() {
        loadSuggestions();
        setEditSuggestion(null);
    }

    useEffect(() => {
        loadAll();
    }, [id]);

    useEffect(() => {
        reloadData();
    }, [suggestionType]);

    const handleChangeTab = (event, newValue) => {
        setSuggestionType(newValue);
    };

    async function handleSuggestionClick(_id, operation) {
        const suggestion = suggestionList.find(s => {
            return s.id === _id;
        });
        const suggestion_identifier =
            suggestion && suggestion.identifier ? suggestion.identifier : '';
        const suggested_content = contentList.find(c => {
            return (
                (suggestion_identifier &&
                    c.identifier === suggestion_identifier) ||
                (suggestion &&
                    suggestion.content &&
                    suggestion.content.id === c.id)
            );
        });

        let suggestion_body = {};
        if (operation !== 'reject') {
            if (suggestionType === 'training') {
                suggestion_body = {
                    operation: suggested_content
                        ? 'edit_content'
                        : 'new_content',
                    content: {
                        id: suggested_content ? suggested_content.id : null,
                        identifier: suggested_content
                            ? suggested_content.identifier
                            : suggestion_identifier,
                        type: suggested_content
                            ? suggested_content.type
                            : 'subject',
                        ...(!suggested_content && {
                            examples: [suggestion.suggestion_text],
                        }),
                    },
                };
            } else if (suggestionType === 'answer') {
                const c_node = dialogNodes.find(n => {
                    return (
                        n.subject === suggested_content.id ||
                        n.intent === suggested_content.id
                    );
                });
                if (c_node) {
                    const dialog_node = await loadNode(c_node.id);
                    const node_settings = dialog_node.settings || {};

                    let suggested_text = suggestion.suggestion_text;
                    if (dialog_node.type === 'generative_ai') {
                        suggested_text = `${node_settings.content || ''}${
                            node_settings.content ? '. \n \n' : ''
                        } ${suggestion.suggestion_text}`;
                    }
                    setCurrNode(dialog_node);
                    suggestion_body = {
                        node: c_node.id || '',
                        operation: 'edit_answer',
                        answer_type: dialog_node.type,
                        text: suggested_text,
                    };
                }
            }
        }

        setEditSuggestion({ id: _id, operation });
        setBody({
            operation,
            text: suggestion.suggestion_text,
            ...suggestion_body,
        });
    }

    function renderAnswerForm() {
        let curr_content = '';

        if (currNode) {
            const node_settings = currNode.settings || {};
            switch (currNode.type) {
                case 'text': {
                    const lng_settings = node_settings[language] || {};
                    const lng_texts = lng_settings.texts || [];

                    curr_content = (
                        <>
                            {lng_texts.map((t, i) => {
                                return (
                                    <p key={i} className="text-left">
                                        {t}
                                    </p>
                                );
                            })}
                        </>
                    );
                    break;
                }
                case 'generative_ai': {
                    curr_content = (
                        <span className="text-left">
                            {node_settings.content}
                        </span>
                    );
                    break;
                }
                default:
                    break;
            }
        }

        return internalLoading ? (
            <Splash />
        ) : (
            <div className="col-12">
                <div
                    className="col-12"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div className="col-md-4 col-12">
                        <FormControl fullWidth className="mb-4">
                            <InputLabel>
                                <LocaleMessage msg="page.qna.nodes.form.action" />
                            </InputLabel>
                            <Select
                                value={body.answer_type || 'text'}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        answer_type: event.target.value,
                                    })
                                }
                            >
                                <MenuItem value="generative_ai">
                                    <LocaleMessage msg="page.qna.nodes.form.action.generative_ai" />
                                </MenuItem>
                                <MenuItem value="text">
                                    <LocaleMessage msg="page.qna.nodes.form.action.text" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="col-12">
                        {curr_content ? (
                            <ParameterArea className="col-12 ">
                                <div className="p-3 text-center">
                                    <span className="mb-5">
                                        <LocaleMessage msg="page.qna.suggestions.training.operation.current_content" />
                                    </span>
                                </div>
                                <div className="p-3">{curr_content}</div>
                            </ParameterArea>
                        ) : null}
                        <ParameterArea className="col-12 text-center">
                            <span className="mb-3">
                                <LocaleMessage msg="page.qna.suggestions.training.operation.new_content" />
                            </span>
                            <div className="p-3">
                                <TextField
                                    className="col-12"
                                    fullWidth
                                    multiline
                                    value={body.text || ''}
                                    onChange={event => {
                                        setBody({
                                            ...body,
                                            text: event.target.value,
                                        });
                                    }}
                                    variant="outlined"
                                />
                            </div>
                        </ParameterArea>
                    </div>
                </div>
            </div>
        );
    }

    function renderContentExamples(
        content,
        examples,
        onUpdateExamples,
        allow_edit
    ) {
        return (
            <ParameterArea className="col-12 pt-3">
                <div className="col-12 p-3 text-center">
                    <LocaleMessage msg="page.qna.content.examples" />
                </div>
                <QnAExamples
                    content={content || {}}
                    contentId=""
                    language={language}
                    examples={examples || []}
                    allowEdit={allow_edit}
                    allowSuggestions={false}
                    handleError={e => handleError(e)}
                    updateExamples={onUpdateExamples}
                />
            </ParameterArea>
        );
    }

    function renderExistingContent() {
        const content = body.content || {};
        const c_type = content.type;
        const content_list =
            c_type && dialogContents[c_type] ? dialogContents[c_type] : [];
        const selected_content = content.id
            ? content_list.find(c => {
                  return c.id === content.id;
              })
            : null;
        const c_examples =
            selected_content && selected_content.examples
                ? selected_content.examples
                : [];
        return (
            <div className="col-12">
                <FormControl className="mb-3" fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.qna.suggestions.training.operation.content" />
                    </InputLabel>
                    <Select
                        value={content.id || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                content: {
                                    ...content,
                                    id: event.target.value,
                                },
                            })
                        }
                    >
                        {content_list.map(c => {
                            return (
                                <MenuItem value={c.id} key={c.id}>
                                    {c.identifier}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {renderContentExamples(
                    selected_content || {},
                    c_examples,
                    () => {},
                    false
                )}
                <ParameterArea className="col-12 row">
                    <div
                        className="col-12 row"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <div className="col-md-4">
                            <span>
                                <LocaleMessage msg="page.qna.suggestions.training.operation.content.add_example" />
                            </span>
                        </div>
                        <div className="col-md-8">
                            <TextField
                                size="small"
                                variant="outlined"
                                label=""
                                fullWidth
                                value={body.text || ''}
                                onChange={e =>
                                    setBody({
                                        ...body,
                                        text: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </ParameterArea>
            </div>
        );
    }

    function renderNewContent() {
        const content = body.content || {};
        const c_examples = content.examples || [];
        return (
            <>
                <div className="col-12">
                    <TextField
                        className="mb-3"
                        label={
                            <LocaleMessage msg="page.qna.content.training.form.identifier" />
                        }
                        fullWidth
                        value={content.identifier || ''}
                        onChange={event =>
                            setBody({
                                ...body,
                                content: {
                                    ...content,
                                    identifier: event.target.name,
                                },
                            })
                        }
                    />
                    {renderContentExamples(
                        body,
                        c_examples,
                        examples => {
                            setBody({
                                ...body,
                                content: {
                                    ...content,
                                    examples,
                                },
                            });
                        },
                        true
                    )}
                </div>
            </>
        );
    }

    function renderTrainingForm() {
        const content = body.content || {};
        const content_form = {
            new_content: renderNewContent(),
            edit_content: renderExistingContent(),
        };

        return (
            <div className="col-12">
                <div className="row">
                    <ParameterArea className="col-md-4 col-12 p-3">
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.qna.suggestions.training.operation" />
                            </InputLabel>
                            <Select
                                value={body.operation || ''}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        operation: event.target.value,
                                    })
                                }
                            >
                                <MenuItem value="new_content">
                                    <LocaleMessage msg="page.qna.suggestions.training.operation.new_content" />
                                </MenuItem>
                                <MenuItem value="edit_content">
                                    <LocaleMessage msg="page.qna.suggestions.training.operation.edit_content" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="mb-3" fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.qna.suggestions.training.operation.content.type" />
                            </InputLabel>
                            <Select
                                value={content.type || ''}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        content: {
                                            ...content,
                                            type: event.target.value,
                                        },
                                    })
                                }
                            >
                                <MenuItem value="intent">
                                    <LocaleMessage msg="page.qna.content.intent" />
                                </MenuItem>
                                <MenuItem value="subject">
                                    <LocaleMessage msg="page.qna.content.subject" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </ParameterArea>
                    <ParameterArea className="col-md-8 col-12 p-3">
                        {content_form[body.operation] || null}
                    </ParameterArea>
                </div>
            </div>
        );
    }

    function renderRejectDialog() {
        const reject_content = (
            <ParameterArea>
                {suggestionType === 'training' ? (
                    <div className="col-12 mt-3">
                        <FormSwitch
                            value={body.ignore || false}
                            onChange={event =>
                                setBody({
                                    ...body,
                                    ignore: event.target.checked,
                                })
                            }
                            label={
                                <LocaleMessage msg="page.qna.suggestions.training.reject.ignore" />
                            }
                        />
                    </div>
                ) : null}
                <div className="col-12 mt-3 mb-3">
                    <LocaleMessage
                        msg={`page.qna.suggestions.training.${
                            body.ignore ? 'ignore' : 'keep'
                        }.description`}
                    />
                </div>
            </ParameterArea>
        );
        return (
            <SimpleDialog
                size="md"
                open={!!editSuggestion}
                onClose={() => setEditSuggestion(null)}
                title={
                    <LocaleMessage msg="page.qna.suggestions.training.reject.title" />
                }
                content={reject_content}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setEditSuggestion(null),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => handleSuggestionSubmit(),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function renderEditDialog() {
        const titles = {
            training: (
                <LocaleMessage msg="page.qna.suggestions.training.title" />
            ),
            answer: <LocaleMessage msg="page.qna.suggestions.answer.title" />,
        };
        const forms = {
            training: renderTrainingForm(),
            answer: renderAnswerForm(),
        };
        return (
            <SimpleDialog
                size="md"
                open={!!editSuggestion}
                onClose={() => setEditSuggestion(null)}
                title={titles[suggestionType]}
                content={forms[suggestionType]}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setEditSuggestion(null),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => handleSuggestionSubmit(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const rowActions = [
            {
                id: 'reject',
                label: <LocaleMessage msg="page.qna.suggestions.reject" />,
                icon: <MdOutlineThumbDown />,
                action: (event, _id) => {
                    handleSuggestionClick(_id, 'reject');
                },
            },
            {
                id: 'approve',
                label: <LocaleMessage msg="page.qna.suggestions.accept" />,
                icon: <MdOutlineThumbUp />,
                action: (event, _id) => {
                    handleSuggestionClick(_id, 'edit');
                },
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers[suggestionType] || []}
                    data={suggestionList}
                    orderDirection="desc"
                    orderColumn="created_timestamp"
                    handleTableRowClick={() => {}}
                    rowActions={rowActions}
                    sortable
                />
            </div>
        );
    }

    return (
        <div className="col-12 mt-3">
            <div
                className="sidecard-header"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                }}
            >
                <div className="col-12 row">
                    <div className="col-md-6 col-12 px-0">
                        <h2>
                            <LocaleMessage msg="page.dialogs.info.qna_suggestions" />
                        </h2>
                        <p />
                        <LocaleMessage msg="page.qna.suggestions.period" />
                    </div>
                    <div className="col-md-6 col-12 px-0 text-right">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdRefresh color="#fff" />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => {
                                reloadData();
                            }}
                        >
                            <LocaleMessage msg="button.refresh_data" />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {editSuggestion ? (
                            <>
                                {editSuggestion.operation === 'reject'
                                    ? renderRejectDialog()
                                    : renderEditDialog()}
                            </>
                        ) : null}
                        <div className="col-12 mb-3">
                            <Tabs
                                indicatorColor="primary"
                                value={suggestionType}
                                onChange={handleChangeTab}
                                className={classes.tabs}
                                centered
                            >
                                {internal_pages.map(p => {
                                    return (
                                        <Tab
                                            key={p.value}
                                            label={
                                                <LocaleMessage
                                                    msg={p.label_code}
                                                />
                                            }
                                            value={p.value}
                                        />
                                    );
                                })}
                            </Tabs>
                        </div>
                        <div className="col-12 row" style={{ flex: 1 }}>
                            {buildListView()}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

QnASuggestions.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
