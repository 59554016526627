/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdRefresh, MdInsertChart } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Divider, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import CounterCard from '~/components/Reports/CounterCard';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import Splash from '~/components/Splash/Inside';

import {
    TotalContainer,
    ChartsContainer,
    TableArea,
    RowArea,
} from '~/pages/Platform/Reports/style';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import lng_all from '~/util/LangMessages';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { Header } from '../styles';

export default function QnAUsage() {
    const dispatch = useDispatch();
    const colors = GetPluginspaceTheme(useTheme());
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_labels = lng_all && lng_all[lang] ? lng_all[lang] : {};

    // const date_format = settings.locale;
    // const date_opt = {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    // };
    // const time_opt = {
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     second: '2-digit',
    //     hourCycle: 'h23',
    // };

    // const minDate = new Date(subMonths(new Date(), 1));
    // const [fromDate, setFromDate] = useState(new Date(subDays(new Date(), 7)));
    // const [untilDate, setUntilDate] = useState(new Date());

    const [statistics, setStatistics] = useState({});
    const [groupedData, setGroupedData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const pluginspace_header = [
        {
            id: 'pluginspace_name',
            label: 'Pluginspace',
        },
    ];

    const headers_grouped = [
        {
            id: 'name',
            label: 'Origem',
        },
        {
            id: 'answers',
            label: <LocaleMessage msg="page.qna.statistics.usage.answers" />,
            align: 'right',
        },
        {
            id: 'input',
            label: (
                <LocaleMessage msg="page.qna.statistics.usage.input_tokens" />
            ),
            align: 'right',
        },
        {
            id: 'output',
            label: (
                <LocaleMessage msg="page.qna.statistics.usage.output_tokens" />
            ),
            align: 'right',
        },
        {
            id: 'searches',
            label: (
                <LocaleMessage msg="page.qna.statistics.usage.online_searches" />
            ),
            align: 'right',
        },
    ];

    const headers_ans_sources = [
        {
            id: 'name',
            label: 'Origem',
        },
        {
            id: 'value',
            label: <LocaleMessage msg="table.headers.quantity" />,
            align: 'right',
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    function parseData(data = {}) {
        const pluginspace_keys = {};
        const dialog_keys = {};

        const answers = data.answers || {};

        const answer_sources = [
            'dialog_content',
            'vertex_search',
            'google_search',
            'fallback',
        ];
        const a_pluginspaces = {};
        const a_dialogs = {};
        const a_sources = {};

        answer_sources.forEach(s => {
            const label_key = `page.qna.statistics.usage.answer_sources.${s}`;
            a_sources[s] = {
                id: s,
                source: s,
                name: lng_labels[label_key],
                value: 0,
            };
        });

        const usage = answers.map(a => {
            const pluginspace = a.pluginspace || {};
            const dialog = a.dialog || {};
            const source = a.answer_engine || '';

            const pluginspace_id = pluginspace.id || '---';
            const dialog_id = dialog.id || '---';

            if (!pluginspace_keys[pluginspace_id]) {
                pluginspace_keys[pluginspace_id] = pluginspace;
            }

            if (!dialog_keys[dialog_id]) {
                dialog_keys[dialog_id] = dialog;
            }

            const tokens = a.tokens || {};

            if (!a_pluginspaces[pluginspace_id]) {
                a_pluginspaces[pluginspace_id] = {
                    answers: 0,
                    input: 0,
                    output: 0,
                    searches: 0,
                };
            }

            a_pluginspaces[pluginspace_id] = {
                ...a_pluginspaces[pluginspace_id],
                answers: a_pluginspaces[pluginspace_id].answers + 1,
                input: a_pluginspaces[pluginspace_id].input + tokens.input,
                output: a_pluginspaces[pluginspace_id].output + tokens.output,
                searches: a_pluginspaces[pluginspace_id].searches + 1,
            };

            if (!a_dialogs[dialog_id]) {
                a_dialogs[dialog_id] = {
                    pluginspace_name: pluginspace.name,
                    answers: 0,
                    input: 0,
                    output: 0,
                    searches: 0,
                };
            }

            a_dialogs[dialog_id] = {
                ...a_dialogs[dialog_id],
                answers: a_dialogs[dialog_id].answers + 1,
                input: a_dialogs[dialog_id].input + tokens.input,
                output: a_dialogs[dialog_id].output + tokens.output,
                searches: a_dialogs[dialog_id].searches + 1,
            };

            if (!a_sources[source]) {
                a_sources[source] = {
                    id: source,
                    source,
                    name: '---',
                    value: 0,
                };
            }

            a_sources[source] = {
                ...a_sources[source],
                value: a_sources[source].value + 1,
            };

            return {
                ...a,
                pluginspace_name: pluginspace.name,
                dialog_name: dialog.name,
            };
        });

        const pluginspace_grouped = Object.keys(a_pluginspaces).map(p => {
            const values = a_pluginspaces[p];
            const obj = pluginspace_keys[p];

            return {
                id: obj ? obj.id : '-',
                name: obj ? obj.name : '---',
                ...values,
            };
        });

        const dialog_grouped = Object.keys(a_dialogs).map(d => {
            const values = a_dialogs[d];
            const obj = dialog_keys[d];

            return {
                id: obj ? obj.id : '-',
                name: obj ? obj.name : '---',
                pluginspace_name: '',
                ...values,
            };
        });

        const source_grouped = answer_sources.map(s => {
            return a_sources[s] || { id: s, name: s, source: s, value: 0 };
        });

        setGroupedData({
            pluginspaces: pluginspace_grouped,
            dialogs: dialog_grouped,
            sources: source_grouped,
        });

        setStatistics({
            ...data,
            usage,
        });
    }

    async function loadData() {
        setIsLoading(true);
        await api
            .get(`qna/admin/statistics`)
            .then(response => {
                const { data } = response;
                parseData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
    }, [date_loc]);

    function renderPieChart(data, key, title, color) {
        return (
            <ReportPieChart
                title={title}
                classes="col-md-3 col-12"
                color={color}
                data={data}
                dataKey={key}
                customization={{
                    height: 200,
                    innerRadius: 50,
                    outerRadius: 80,
                }}
            />
        );
    }

    function renderGroupedChartRow(data) {
        return (
            <>
                {renderPieChart(
                    data,
                    'answers',
                    <LocaleMessage msg="page.qna.statistics.usage.answers" />,
                    colors.dashboard_graphs
                )}
                {renderPieChart(
                    data,
                    'input',
                    <LocaleMessage msg="page.qna.statistics.usage.input_tokens" />,
                    colors.dashboard_cards
                )}
                {renderPieChart(
                    data,
                    'output',
                    <LocaleMessage msg="page.qna.statistics.usage.output_tokens" />,
                    colors.dashboard_graphs
                )}
                {renderPieChart(
                    data,
                    'searches',
                    <LocaleMessage msg="page.qna.statistics.usage.online_searches" />,
                    colors.dashboard_cards
                )}
            </>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.qna.statistics.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginbot_qna',
                    title: <LocaleMessage msg="page.qna.title" />,
                },
            ]}
            loading={false}
        >
            <div className="col-12 mb-5 mt-5">
                <CardSideBordered
                    hide
                    title={<LocaleMessage msg="page.qna.statistics.title" />}
                    Icon={MdInsertChart}
                >
                    <>
                        <div className="col-12 mt-3">
                            <div
                                className="sidecard-header"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                }}
                            >
                                <Header className="col-12 row">
                                    <div className="col-md-4 col-12" />
                                    <div className="col-md-4 col-12">
                                        <h1 className="card-title">
                                            <LocaleMessage msg="page.qna.statistics.dialogs.title" />
                                        </h1>
                                    </div>
                                    <div className="col-md-4 col-12 px-0 text-right">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={
                                                <MdRefresh color="#fff" />
                                            }
                                            style={{
                                                whiteSpace: 'nowrap',
                                                padding: '5px 20px',
                                            }}
                                            onClick={() => {
                                                loadData();
                                            }}
                                        >
                                            <LocaleMessage msg="button.refresh_data" />
                                        </Button>
                                    </div>
                                </Header>
                            </div>
                            <div className="sidecard-body">
                                {isLoading ? (
                                    <Splash />
                                ) : (
                                    <>
                                        <TotalContainer className="mt-3 mb-5">
                                            <CounterCard
                                                classes="col-md-4 col-12"
                                                title={
                                                    <LocaleMessage msg="page.qna.statistics.dialogs.all" />
                                                }
                                                value={
                                                    statistics &&
                                                    statistics.dialogs &&
                                                    statistics.dialogs.list
                                                        ? statistics.dialogs
                                                              .list.length
                                                        : 0
                                                }
                                            />
                                            <CounterCard
                                                classes="col-md-4 col-12"
                                                title={
                                                    <LocaleMessage msg="page.qna.statistics.dialogs.month" />
                                                }
                                                value={
                                                    statistics &&
                                                    statistics.dialogs &&
                                                    statistics.dialogs.recent
                                                        ? statistics.dialogs
                                                              .recent.length
                                                        : 0
                                                }
                                            />
                                        </TotalContainer>

                                        <Divider />

                                        <RowArea className="mt-5 mb-5">
                                            <p className="card-title">
                                                <LocaleMessage msg="page.qna.statistics.pluginspaces" />
                                            </p>
                                        </RowArea>

                                        <ChartsContainer className="row mb-5">
                                            {renderGroupedChartRow(
                                                groupedData.pluginspaces
                                            )}
                                        </ChartsContainer>
                                        <TotalContainer className="row mb-5">
                                            <TableArea
                                                className="col-md-10 col-12 table-responsive-material"
                                                style={{
                                                    minHeight: '350px',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <DataTable
                                                    maxHeight="100%"
                                                    headerColumns={
                                                        headers_grouped
                                                    }
                                                    data={
                                                        groupedData.pluginspaces ||
                                                        []
                                                    }
                                                    orderDirection="desc"
                                                    orderColumn="answers"
                                                    hasActions={false}
                                                    sortable
                                                    defaultRowsPerPage={10}
                                                />
                                            </TableArea>
                                        </TotalContainer>

                                        <Divider />

                                        <RowArea className="mt-5 mb-5">
                                            <p className="card-title">
                                                <LocaleMessage msg="page.qna.statistics.dialogs" />
                                            </p>
                                        </RowArea>
                                        <ChartsContainer className="row mb-5">
                                            {renderGroupedChartRow(
                                                groupedData.dialogs
                                            )}
                                        </ChartsContainer>
                                        <TotalContainer className="row mb-5">
                                            <TableArea
                                                className="col-md-10 col-12 table-responsive-material"
                                                style={{
                                                    minHeight: '350px',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <DataTable
                                                    maxHeight="100%"
                                                    headerColumns={[
                                                        ...pluginspace_header,
                                                        ...headers_grouped,
                                                    ]}
                                                    data={
                                                        groupedData.dialogs ||
                                                        []
                                                    }
                                                    orderDirection="desc"
                                                    orderColumn="answers"
                                                    hasActions={false}
                                                    sortable
                                                    defaultRowsPerPage={10}
                                                />
                                            </TableArea>
                                        </TotalContainer>

                                        <Divider />

                                        <RowArea className="mt-5 mb-5">
                                            <p className="card-title">
                                                <LocaleMessage msg="page.qna.statistics.usage.answer_sources" />
                                            </p>
                                        </RowArea>

                                        <ChartsContainer className="row mb-5">
                                            <ReportPieChart
                                                classes="col-md-6 col-12"
                                                color={colors.dashboard_graphs}
                                                data={groupedData.sources}
                                                dataKey="value"
                                                customization={{
                                                    height: 300,
                                                    innerRadius: 60,
                                                    outerRadius: 100,
                                                }}
                                            />
                                            <TableArea
                                                className="col-md-5 col-12 table-responsive-material"
                                                style={{
                                                    minHeight: '350px',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <DataTable
                                                    maxHeight="100%"
                                                    headerColumns={
                                                        headers_ans_sources
                                                    }
                                                    data={
                                                        groupedData.sources ||
                                                        []
                                                    }
                                                    orderDirection="desc"
                                                    orderColumn="value"
                                                    hasActions={false}
                                                    sortable
                                                    defaultRowsPerPage={10}
                                                    hasFooter={false}
                                                />
                                            </TableArea>
                                        </ChartsContainer>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                </CardSideBordered>
            </div>
        </PageContent>
    );
}

QnAUsage.propTypes = {};
